import { CircleX, Phone } from "lucide-react";
import React from "react";
// import illu from "/images/illu-schedule.png";
const BookCall = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className="border tw-flex tw-w-16 tw-h-16 hover:-tw-rotate-45 tw-transition-all tw-200 tw-ease-in-out tw-fixed tw-right-10 tw-bottom-10 tw-bg-blue-600 tw-p-5 tw-rounded-full tw-shadow-lg tw-z-10 tw-cursor-pointer"
        onClick={togglePopup}
      >
        <Phone className="tw-text-white" />
      </div>
      {isOpen && (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-20 tw-flex tw-items-center tw-justify-center tw-z-20 tw-w-full">
          <div className="tw-bg-white tw-p-8 tw-rounded-lg tw-shadow-lg tw-relative">
            <span
              className="tw-absolute tw-right-4 tw-top-4 tw-cursor-pointer"
              onClick={togglePopup}
            >
              <CircleX />
            </span>
            <h2 className="tw-text-3xl tw-text-blue-800 tw-mb-4">
              Booker un appel !
            </h2>
            <p className="tw-mb-4">
              Réservez votre créneau avec l'un de nos experts.
            </p>
            <div className="tw-flex tw-flex-col tw-gap-7">
              <div className="tw-flex tw-flex-1 tw-max-w-96">
                <img
                  src="/assets/images/illu-schedule.png"
                  alt="illustration"
                />
              </div>
              <div className="w-full tw-flex tw-items-center">
                <a
                  href="https://meetings-eu1.hubspot.com/tom-bonnot"
                  className="p-4 tw-w-full"
                  target="_blank"
                >
                  <button className="tw-bg-blue-600 tw-text-white tw-py-2 tw-px-4 tw-rounded-lg tw-w-full tw-text-center">
                    Réserver
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookCall;
